var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"cols":12}},[_c('b-card',{staticClass:"border mt-1"},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Auto Partner")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('Warning'))+" ")]),_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(" หลังกดบันทึก ระบบจะไปดึงตั้งค่าที่ระบบ Auto มาบันทึกเพิ่มเติม, Logo, Slide Images, etc... ")])])])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"form"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Auto Access Token')))]),_c('validation-provider',{attrs:{"name":"Auto Access Token","rules":_vm.rulesAccessToken},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":_vm.$t('Auto Access Token'),"autocomplete":"off"},model:{value:(_vm.formData.accessToken),callback:function ($$v) {_vm.$set(_vm.formData, "accessToken", $$v)},expression:"formData.accessToken"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Auto BaseUrl Api')))]),_c('validation-provider',{attrs:{"name":"Auto BaseUrl Api","rules":_vm.rulesBaseUrlApi},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":_vm.$t('Auto BaseUrl Api'),"autocomplete":"off"},model:{value:(_vm.formData.baseUrlApi),callback:function ($$v) {_vm.$set(_vm.formData, "baseUrlApi", $$v)},expression:"formData.baseUrlApi"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Auto Web Name')))]),_c('validation-provider',{attrs:{"name":"Auto Web Name","rules":_vm.rulesBaseUrlApi},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":_vm.$t('Auto Web Name'),"autocomplete":"off"},model:{value:(_vm.formData.individual),callback:function ($$v) {_vm.$set(_vm.formData, "individual", $$v)},expression:"formData.individual"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Auto Prefix')))]),_c('validation-provider',{attrs:{"name":"Auto Prefix","rules":_vm.rulesBaseUrlApi},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":_vm.$t('Auto Prefix'),"autocomplete":"off"},model:{value:(_vm.formData.prefix),callback:function ($$v) {_vm.$set(_vm.formData, "prefix", $$v)},expression:"formData.prefix"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Game Domain')))]),_c('validation-provider',{attrs:{"name":"Game Domain","rules":_vm.rulesBaseUrlApi},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":_vm.$t('Game Domain'),"autocomplete":"off"},model:{value:(_vm.formData.domainName),callback:function ($$v) {_vm.$set(_vm.formData, "domainName", $$v)},expression:"formData.domainName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"switch":""},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}},[_c('span',{staticClass:"h5 font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Disable / Enable')))])])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"primary","disabled":_vm.$wait.is('loading-on-save')},on:{"click":_vm.onSave}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")],1),_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-secondary","size":"lg","disabled":_vm.$wait.is('loading-on-save'),"type":"reset"}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Reset'))+" ")],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }