<template>
  <b-row class="justify-content-md-center">
    <b-col :cols="12">
      <b-card
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Auto Partner</span>
          </b-card-title>
        </b-card-header>

        <b-col cols="12">
          <b-alert
            variant="warning"
            show
          >
            <h4 class="alert-heading">
              {{ $t('Warning') }}
            </h4>
            <div class="alert-body">
              <span>
                หลังกดบันทึก ระบบจะไปดึงตั้งค่าที่ระบบ Auto มาบันทึกเพิ่มเติม, Logo, Slide Images, etc...
              </span>
            </div>
          </b-alert>
        </b-col>

        <b-col cols="12">
          <validation-observer ref="form">
            <!-- form -->
            <b-form autocomplete="off">
              <b-row>

                <!-- access token -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('Auto Access Token') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Auto Access Token"
                      :rules="rulesAccessToken"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="formData.accessToken"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          :placeholder="$t('Auto Access Token')"
                          autocomplete="off"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ access token -->

                <!-- base url api -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('Auto BaseUrl Api') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Auto BaseUrl Api"
                      :rules="rulesBaseUrlApi"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="formData.baseUrlApi"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          :placeholder="$t('Auto BaseUrl Api')"
                          autocomplete="off"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ base url api -->

                <!-- auto individual -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('Auto Web Name') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Auto Web Name"
                      :rules="rulesBaseUrlApi"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="formData.individual"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          :placeholder="$t('Auto Web Name')"
                          autocomplete="off"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ auto individual -->

                <!-- auto prefix -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('Auto Prefix') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Auto Prefix"
                      :rules="rulesBaseUrlApi"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="formData.prefix"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          :placeholder="$t('Auto Prefix')"
                          autocomplete="off"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ auto prefix -->

                <!-- game domain -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('Game Domain') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Game Domain"
                      :rules="rulesBaseUrlApi"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="formData.domainName"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          :placeholder="$t('Game Domain')"
                          autocomplete="off"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ game domain -->

                <b-col cols="12">
                  <b-form-checkbox
                    v-model="formData.active"
                    class="custom-control-success"
                    switch
                  >
                    <span class="h5 font-weight-bolder">{{ $t('Disable / Enable') }}</span>
                  </b-form-checkbox>
                </b-col>

                <!-- buttons -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    :disabled="$wait.is('loading-on-save')"
                    @click="onSave"
                  >
                    <feather-icon icon="SaveIcon" />
                    {{ $t('Save') }}
                  </b-button>

                  <b-button
                    variant="outline-secondary"
                    class="btn-icon ml-2"
                    size="lg"
                    :disabled="$wait.is('loading-on-save')"
                    type="reset"
                  >
                    <feather-icon icon="DeleteIcon" />
                    {{ $t('Reset') }}
                  </b-button>
                </b-col>
                <!--/ buttons -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import { required } from "@validations";
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { pick } from 'lodash'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      formData: {
        active: false,
        accessToken: '',
        baseUrlApi: '',
        individual: '',
        prefix: '',
        domainName: '',
      },
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    rulesAccessToken() {
      return 'required|min:1|max:100'
    },
    rulesBaseUrlApi() {
      return 'required|min:1|max:100'
    },
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    async getSetting() {
      this.$wait.start('loading-on-get-setting')
      try {
        const { data } = await this.$http.get('/auto-partner/setting')
        if (data.success) {
          const setting = data?.data?.data
          this.formData = pick(setting, Object.keys(this.formData))
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-get-setting')
      }
    },
    async onSave() {
      const isValidForm = await this.$refs.form.validate()
      if (!isValidForm) {
        return
      }

      this.$wait.start('loading-on-save')
      try {
        const { data } = await this.$http.post('/auto-partner/setting', this.formData)
        if (data.success) {
          const autoSuccess = data?.data?.auto?.success
          const autoMessage = data?.data?.auto?.message

          if (!autoSuccess) {
            this.$swal({
              icon: 'error',
              title: `${this.$t('ACCOUNT_SAVE_SUCCESS')}\nไม่สามารถบันทึกข้อมูล Auto ได้ Error:${autoMessage}`,
            })
          } else {
            this.$swal({
              icon: 'success',
              title: this.$t('ACCOUNT_SAVE_SUCCESS'),
            })
          }
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
  },
}
</script>
